<template>
  <div class="CandidatureItem">

    <div class="row">
      <div class="col-12">
        <strong class="gavc-text-green"> <u>Présentation</u> </strong>
        <p style="font-size: 0.9rem; white-space: pre-line">
          {{ candidature.description }}
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <strong class="gavc-text-green"> <u>Informations sur le repreneur</u> </strong>
      </div>
    </div>

    <div class="row mt-1">
      <div class="col-12 col-lg-6">
        <strong> Prénom : </strong>
        <span> {{ candidature.repreneur.user.first_name }} </span>
      </div>
      <div class="col-12 col-lg-6">
        <strong> Nom : </strong>
        <span> {{ candidature.repreneur.user.last_name }} </span>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-lg-6">
        <strong> Téléphone : </strong>
        <span v-if="candidature.repreneur.phone_number">
          {{ candidature.repreneur.phone_number.match(/.{1,2}/g).join(' ') }}
        </span>
        <span v-else>
          Pas de numéro de téléphone renseigné
        </span>
      </div>
      <div class="col-lg-6">
        <strong> Mail : </strong>
        <span> {{ candidature.repreneur.user.username }} </span>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-12">
        <strong> Adresse : </strong>
        <span> {{ candidature.repreneur.address }}, {{candidature.repreneur.zip_code}} {{candidature.repreneur.city}}</span>
      </div>
    </div>

    <div class="row mt-1">
      <div class="col-12">
        <strong> Projet de reprise : </strong>
        <span> {{ repreneurPersonalProject(candidature.repreneur.personal_project) }}</span>
      </div>
    </div>

    <div v-if="candidature.repreneur.personal_project === 'AUTRE'" class="row mt-1">
      <div class="col-12">
        <strong> Précisions sur le projet de reprise : </strong>
        <span style="white-space: pre-line"> {{ candidature.repreneur.personal_project_precision}}</span>
      </div>
    </div>

    <div class="row mt-1" >
      <div class="col-12">
        <strong> Le repreneur a une formation dans le domaine d'activité de l'entreprise qu'il souhaite reprendre : </strong>
        <span v-if="candidature.repreneur.has_formation_domain_activite"> Oui </span>
        <span v-else> Non</span>
      </div>
    </div>
    <div v-if="candidature.repreneur.has_formation_domain_activite" class="row mt-1" >
      <div class="col-12">
        <strong> Précisions données : </strong> <br>
        <span style="white-space: pre-line"> {{candidature.repreneur.formation_precision}} </span>
      </div>
    </div>
    <div class="row mt-1" >
      <div class="col-12">
        <strong> Le repreneur a de l'expérience dans la gestion d'entreprise : </strong>
        <span v-if="candidature.repreneur.has_experience_gestion_entreprise"> Oui </span>
        <span v-else> Non </span>
      </div>
    </div>


    <div class="mt-3" v-if="!candidature.repreneur.is_personne_physique">
      <div class="row">
        <div class="col-12">
          <strong class="gavc-text-green"> <u>Informations sur l'entreprise du repreneur</u> </strong>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12 col-lg-6">
          <strong> Raison sociale : </strong>
          <span> {{ candidature.repreneur.company.name }} </span>
        </div>
        <div class="col-12 col-lg-6">
          <strong> Siren : </strong>
          <span> {{ candidature.repreneur.company.siren.match(/.{1,3}/g).join(' ') }} </span>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12 col-lg-6">
          <strong> Capital : </strong>
          <span v-if="candidature.repreneur.company.capital">
            {{ formatThousandSeparatorNumber(candidature.repreneur.company.capital) }} €
          </span>
          <span v-else>
            Information en cours de remplissage par le repreneur
          </span>
        </div>
        <div class="col-12 col-lg-6">
          <strong> Date de création : </strong>
          <span v-if="candidature.repreneur.company.date_creation">
            {{ $dayjs(candidature.repreneur.company.date_creation).format('DD/MM/YYYY') }}
          </span>
          <span v-else>
            Information en cours de remplissage par le repreneur
          </span>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12 col-lg-6">
          <strong> Forme sociale : </strong>
          <span> {{ candidature.repreneur.company.forme_sociale }} </span>
        </div>
        <div class="col-12 col-lg-6">
          <strong> Procédure collective : </strong>
          <span> {{ procedureCollectiveRender(candidature.repreneur.company.procedure_collective) }} </span>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12">
          <strong> Adresse : </strong>
          <span> {{ candidature.repreneur.company.address }}, {{candidature.repreneur. company.zip_code}} {{candidature.repreneur. company.city}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import renderMixins from '@/mixins/renderMixins'

export default {
  name: 'candidatureFicheDetaillee',
  props: {
    candidature: {
      type: Object,
      default: function () {
        return {
          created_at: '',
          description: '',
          repreneur: {
            user: {
              first_name: '',
              last_name: ''
            },
            birthdate: '01/01/1990'
          }
        }
      }
    }
  },
  mixins: [
    renderMixins
  ],
  mounted () {
    this.$dayjs.locale('fr')
  }
}
</script>
